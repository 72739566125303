//// This file is automatically compiled by Webpack, along with any other files
//// present in this directory. You're encouraged to place your actual application logic in
//// a relevant structure within app/javascript and only use these pack files to reference
//// that code so it'll be compiled.
//
//import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
//import * as ActiveStorage from "@rails/activestorage"
//import "channels"
//
//Rails.start()
//Turbolinks.start()
//ActiveStorage.start()
//
//require("trix")
//require("@rails/actiontext")
//import "controllers"

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application
// logic in a relevant structure within app/javascript and only use these pack
// files to reference that code so it'll be compiled.

NodeList.prototype.forEach = Array.prototype.forEach;
NodeList.prototype.map = Array.prototype.map;
NodeList.prototype.reduce = Array.prototype.reduce;
NodeList.prototype.filter = Array.prototype.filter;

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import LocalTime from "local-time";
//const dropin = require("braintree-web-drop-in");
//import _ from "lodash";
import { ready, PATCH, POST, GET } from "../helpers"; // TODO via npm

//require("trix"); // LAZY
require("@rails/actiontext"); // LAZY
//require("@fortawesome/fontawesome-free/js/all");

window.UIkit = require("uikit/dist/js/uikit");
require("uikit/dist/js/uikit-icons");

//window.Recaptcha = require("../recaptcha"); // TODO via npm LAZY

window.Rails = Rails;
Rails.confirm = (message, element) => {
  const isRemote = element.getAttribute('data-remote');

  if (isRemote) {
    UIkit.modal.confirm(message).then(() => {
      const callback = Rails.fire(element, 'confirm:complete', [true]);
      Rails.handleRemote.call(element, {
        target: element,
        preventDefault: () => { },
        stopPropagation: () => { },
        stopImmediatePropagation: () => { },
      });
    }, () => {
      const callback = Rails.fire(element, 'confirm:complete', [false]);
    });
  } else {
    // TODO ... not sure how to do the submit without triggering the
    // dialog again
    return confirm(message);
  }

  // let promise resolve
  return false;
};

Rails.start();
Turbolinks.start();
ActiveStorage.start();
LocalTime.start();

const handleBuyNow = (e) => {
  //let element = this;yyp
  let element = e.target;
  Rails.stopEverything(e);
  const params = {
    order: {
      sku_id: element.getAttribute('data-sku-id'),
      quantity: element.getAttribute('data-quantity'),
    },
  };

  POST('/cart/checkout/buynow', {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/javascript',
    },
    body: JSON.stringify(params)
  })
    .then(response => response.text())
    .then(App.appendScript);
};

Rails.delegate(document, 'a[href="#buy-now"]', 'click', handleBuyNow);

const handleFilter = (e) => {
  const url = e.target.getAttribute('data-url') || window.location.pathname;
  const name = e.target.getAttribute('name');
  const key = `${name}[]`;
  const params = new URLSearchParams(decodeURI(window.location.search));
  params.delete(key);
  const values = document.querySelectorAll('[data-filter]').reduce(
    (memo, el) => {
      if (el.name == name) {
        if (el.checked) { memo.push(el.value); }
        if (el.selectedOptions && el.selectedOptions.length > 0) {
          const option = el.selectedOptions[0];
          if (option.value && option.value.length > 0) memo.push(option.value);
        }
      }
      return memo;
    }, []
  );
  values.forEach((val) => params.append(key, val));
  const search = `?${decodeURI(params.toString())}`;
  const fullPath = (params.toString().length > 1) ? `${url}${search}` : url;
  GET(fullPath, { headers: { 'Accept': 'application/javascript', } })
    .then(response => {
      if (!response.ok) {
        response.text().then(text => {
          console.log(text);
        });
      } else {
        return response.text();
      }
    })
    .then(text => {
      window.App.appendScript(text);
      window.history.pushState({}, document.title, fullPath);
    });
};

Rails.delegate(
  document,
  'input[type=checkbox][data-filter], select[data-filter]',
  'change',
  handleFilter
);

window.App = window.App || {};

window.App.showFlash = function () {
  document.querySelectorAll("#flash-wrapper").forEach((el) => {
    el.querySelectorAll("i").forEach((el) => {
      UIkit.notification(el.innerHTML, {
        status: el.getAttribute("class"),
        pos: "top-right",
        timeout: "5000",
      });
    });
  });
};
window.App.setFlash = function (content) {
  var wrapper = document.querySelector("#flash-wrapper");
  if (wrapper) {
    wrapper.innerHTML = content;
  }
  App.showFlash();
  return wrapper;
};
window.App.updateCart = function (content) {
  var wrapper = document.querySelector('#nav-cart');
  if (wrapper) {
    wrapper.outerHTML = content;
  }
  return wrapper;
};
window.App.working = function (bool) {
  if (bool || bool === undefined) {
    UIkit.modal("#fx-working").show()
  } else {
    UIkit.modal("#fx-working").hide()
  }
};
window.App.parseHtml = function (html) {
  let template = document.createElement('template');
  html = html.trim();
  template.innerHTML = html;
  return template.content.firstChild;
};
window.App.appendScript = function (text) {
  // shamelessly stolen from rails-ujs
  var script = document.createElement("script");
  script.setAttribute("nonce", Rails.cspNonce());
  script.text = text;
  document.head.appendChild(script).parentNode.removeChild(script);
};
window.App.getCookie = function (name) {
  var cname = name + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(cname) == 0) {
      return c.substring(cname.length, c.length);
    }
  }
  return "";
};

window.App.setTimezone = function (timezone) {
  var zone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  PATCH("/timezone", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ zone: zone }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (App.handleTimezone) {
        App.handleTimezone(data);
      } else {
        console.log("Timezone changed. Please refresh, if needed.");
      }
    });
};
window.App.checkTimezone = function (timezone) {
  if (App.isTimezoneStale()) {
    console.log("Updating TZ");
    App.setTimezone();
  }
};

// ts - seconds since epoch
// debounceInterval - seconds to delay updating the timezone
window.App.isTimezoneStale = function (timezone) {
  const ts = parseInt(App.getCookie("timezoneTs"));
  const debounceInterval = 1800;
  return ts === NaN || (ts + debounceInterval) * 1000 < Date.now();
};

// Add recaptcha
window.App.dropinOnSubmit = function (form, inputId) {
  const input = form.querySelector(`input#${inputId}`);

  // bail if no input to set
  if (!input) { return }

  const isRemote = form.getAttribute('data-remote');
  const indicator = form.querySelector('.fx-loading');
  const container = form.querySelector('.dropin');

  // bail if no already created
  if (container && container.children.length > 0) { return }

  GET('/client-tokens/braintree', {
    headers: {
      'Accept': 'text/plain',
      'Content-Type': 'text/plain'
    }
  })
    .then(response => response.text())
    .then((authorization) => {
      if (authorization) {
        import('braintree-web-drop-in').then((dropin) => {
          dropin.create({
            authorization,
            container,
            card: { cardholderName: true }
          }, (err, instance) => {
            // TODO extract a hook on payment method submits to accept functions
            // that wait for them all to finish then submit.

            if (err) {
              console.log('create', err);
              return;
            }

            if (indicator) indicator.style.display = 'none';
            if (container) container.style.display = 'block';
            if (isRemote) { form.removeAttribute('data-remote') }

            form.addEventListener('submit', (event) => {
              event.preventDefault();
              App.working(true);

              instance.requestPaymentMethod((err, paymentMethod) => {
                if (err) {
                  // i.e. DropinError { name: "...", message: "..." }
                  console.log('requestPaymentMethod:', err);
                  return;
                }

                input.value = paymentMethod.nonce;

                if (isRemote) {
                  form.setAttribute('data-remote', true);
                  //TODO el.setAttribute('data-recaptcha-token', token);
                  Rails.handleRemote.call(form, event);
                } else {
                  form.submit();
                }

                // _emit is seemd to be called by something that results in an
                // error but doesn't seem to cause a problem.
                instance.teardown();
              });
            });
          });
        });
      } else {
        console.log('Missing client token');
      }
    });
};
window.App.initAddPaymentMethodModal = function () {
  const addPaymentMethodModal = document.querySelector('#add-payment-method-modal');
  if (addPaymentMethodModal) {
    UIkit.util.on(addPaymentMethodModal, 'shown', (event) => {
      const addPaymentMethodForm = document.querySelector('form#add-payment-method');
      if (addPaymentMethodForm) {
        App.dropinOnSubmit(addPaymentMethodForm, 'add-nonce');
      }
    });
  }
};

window.loadRecaptcha = function() {
  if (document.querySelector('script[id=recaptcha-src]') == null) {
    const tag = document.createElement('script');
    tag.id = 'recaptcha-src';
    tag.src = 'https://www.google.com/recaptcha/api.js?onload=recaptchaLoaded&render=explicit';
    tag.async = true;
    tag.defer = true;
    document.head.appendChild(tag);
    console.log('Recaptcha script appended.');
  }
};

window.recaptchaLoaded = function() {
  console.log('Google Recaptcha Loaded');
  import("../recaptcha").then((recaptcha) => {
    window.Recaptcha = recaptcha;
    ready(() => {
      Recaptcha.load();
      console.log('Recaptcha Load');
    });
  });
};

ready(function () {

  App.checkTimezone();

  // uikit-builders flash
  App.showFlash();

  document.body.addEventListener("ajax:error", (event) => {
    console.log("ajax:error", event);
  });

  // recaptcha
  document.body.addEventListener("ajax:beforeSend", (event) => {
    const [xhr, options] = event.detail;
    var token = event.target.getAttribute("data-recaptcha-token");
    if (token) xhr.setRequestHeader("X-RECAPTCHA", token);
  });

  //Recaptcha.load();

  // Drag & drop file upload
  var bar = document.getElementById("js-progressbar");
  UIkit.upload('.fx-upload', {
    url: '/admin/medias',
    multiple: true,
    name: 'media',
    beforeSend: function (environment) {
      var { data, method, headers, xhr, responseType } = environment;
      // 'Accept': 'application/javascript' will invoke create.js.*
      //
      // Might want to change to html and just return the image, and append
      // client side, but this allows flash etc.
      environment.headers = Object.assign(environment.headers, {
        'Accept': 'application/javascript',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': Rails.csrfToken(),
        'X-Resource': this.$el.getAttribute('data-resource')
      })
    },
    loadStart: function (e) {
      bar.removeAttribute('hidden');
      bar.max = e.total;
      bar.value = e.loaded;
    },
    progress: function (e) {
      bar.max = e.total;
      bar.value = e.loaded;
    },
    loadEnd: function (e) {
      bar.max = e.total;
      bar.value = e.loaded;
    },
    complete: function (response) {
      App.appendScript(response.responseText);
    },
    completeAll: function (response) {
      setTimeout(function () {
        bar.setAttribute('hidden', 'hidden');
      }, 1000);
    },
    fail: function (err) {
      console.log(err);
    },
    error: function (err) {
      console.log(err);
    },
  });

  // REGISTERED
  App.initAddPaymentMethodModal();

  // GUEST
  const checkoutForm = document.querySelector('form#checkout-payment');
  if (checkoutForm) {
    App.dropinOnSubmit(checkoutForm, 'elnonce');
  }

  if (document.querySelector('trix-editor') != null) {
    import('trix').then((trix) => {
      console.log('Trix loaded');
    });
  }

  window.onscroll = function() { window.loadRecaptcha(); console.log('scroll'); }
  window.onmousedown = function() { window.loadRecaptcha(); console.log('mousedown'); }
  window.onkeypress = function() { window.loadRecaptcha(); console.log('keypress'); }

  if (window.Recaptcha) Recaptcha.load();

  const signupModal = document.querySelector('#modal-container');
  if (signupModal) {
    setTimeout(function(){
      document.addEventListener("mouseleave", function(e){
        if( e.clientY < 0 ) {
          if (!localStorage.getItem('subscribeShown')) {
            UIkit.modal(signupModal).show();
            localStorage.setItem('subscribeShown', true);
          }
        }
      }, false);
    }, 10000);
  }

  const ecomworldModal = document.querySelector('#modal-ecomworld');
  if (ecomworldModal) {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('utm_source') == 'ecomworld') {
      setTimeout(function(){
        if (!localStorage.getItem('ecomworldShown')) {
          UIkit.modal(ecomworldModal).show();
          localStorage.setItem('ecomworldShown', true);
        }
      }, 3000);
    }
  }

});

import "channels";
import "controllers";

